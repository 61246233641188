import * as Yup from "yup";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Select from "react-select";
import { useFormik } from "formik";
import useQueryParams from "../../utils/query_params";

const FILTER_QUERY = gql`
  query ($program: String, $personnel: String, $status: String) {
    advanceFilters(program: $program, personnel: $personnel, status: $status) {
      status
      programs {
        id
        programName
      }
      clients {
        id
        clientName
      }
      personnels {
        id
        firstName
        lastName
      }
      statuses
    }
  }
`;
// Validation schema
const validationSchema = Yup.object({
  client_name: Yup.string().required("Required"),
  personnel_id: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

export default function Filter(props) {
  const { getQueryParam, setQueryParam, setMultipleQueryParams } =
    useQueryParams();

  const { loading, error, data, refetch } = useQuery(FILTER_QUERY, {
    variables: { program: "all", personnel: "all", status: "all" },
  });

  // const [ clientName, setClientName ] = useState( { label: "All", value: "all" } );

  const [clientName, setClientName] = useState(
    getQueryParam("clientId")
      ? {
          label: getQueryParam("clientLabel"),
          value: getQueryParam("clientId"),
        }
      : { label: "All", value: "all" }
  );

  const [programName, setProgramName] = useState(
    getQueryParam("programId")
      ? {
          label: getQueryParam("programLabel"),
          value: getQueryParam("programId"),
        }
      : { label: "All", value: "all" }
  );

  const [personnelId, setPersonnelId] = useState(
    getQueryParam("personnelId")
      ? {
          label: getQueryParam("personnelLabel"),
          value: getQueryParam("personnelId"),
        }
      : { label: "All", value: "all" }
  );

  // const [personnelId, setPersonnelId] = useState({
  //   label: "All",
  //   value: "all",
  // });

  const [status, setStatus] = useState(
    getQueryParam("status")
      ? {
          label: getQueryParam("statusLabel"),
          value: getQueryParam("status"),
        }
      : { label: "All", value: "all" }
  );

  // const [ status, setStatus ] = useState( {
  //   label: "All",
  //   value: "all",
  // });

  const [clientNameOptions, setClientNameOptions] = useState([]);

  const [programNameOptions, setProgramNameOptions] = useState([]);
  const [personnelIdOptions, setPersonnelOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([
    {
      label: "All",
      value: "all",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Released",
      value: "released",
    },
    {
      label: "Liquidated",
      value: "liquidated",
    },
  ]);

  useEffect(() => {
    // Update formik values when data is available
    if (data && data.advanceFilters) {
      let clients = data.advanceFilters.clients.map((program) => ({
        label: program.clientName,
        value: program.id,
      }));
      setClientNameOptions([...[{ label: "All", value: "all" }], ...clients]);
      let programs = data.advanceFilters.programs.map((program) => ({
        label: program.programName,
        value: program.id,
      }));
      setProgramNameOptions([...[{ label: "All", value: "all" }], ...programs]);
      let personnels = data.advanceFilters.personnels.map((personnel) => ({
        value: personnel.id,
        label: `${personnel.firstName} ${personnel.lastName}`,
      }));
      setPersonnelOptions([...[{ label: "All", value: "all" }], ...personnels]);
    }
  }, [data]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const formik = useFormik({
    initialValues: {
      program_name: "",
      client_name: "",
      personnel_id: "",
      status: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log({
        variables: {
          client_name: values.client_name,
          personnel_id: values.personnel_id,
          status: values.status,
        },
      });
    },
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12 -mt-5">
          <div className="grid grid-cols-1 gap-x-9 gap-y-0 md:gap-y-8 sm:grid-cols-12 md:col-span-2 items-end">
            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-client_name-input"
                className="label-on-input z-10"
              >
                Program
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  autoFocus
                  options={programNameOptions}
                  name="program_name"
                  id="program_name"
                  instanceId="program_name"
                  value={{
                    label: programName.label,
                    value: programName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("program_name", selected.value);
                    // refetch({
                    //   program: selected.value,
                    //   personnel: "all",
                    // });
                    setProgramName(selected);
                    //setPersonnelId({ label: "All", value: "all" });
                    //setStatus({ label: "All", value: "all" });

                    setMultipleQueryParams({
                      programId: selected.value,
                      programLabel: selected.label,
                      // personnelId: null,
                      // personnelLabel: "All",
                      // status: null,
                      // statusLabel: "All",
                      page: 1,
                    });
                  }}
                  className="filter-field"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.program_name}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-client_name-input"
                className="label-on-input z-10"
              >
                Client
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={clientNameOptions}
                  name="client_name"
                  id="client_name"
                  instanceId="client_name"
                  value={{
                    label: clientName.label,
                    value: clientName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("client_name", selected.value);
                    setClientName(selected);
                    //setPersonnelId({ label: "All", value: "all" });
                    //setStatus({ label: "All", value: "all" });

                    setMultipleQueryParams({
                      clientId: selected.value,
                      clientLabel: selected.label,
                      //personnelId: null,
                      // personnelLabel: "All",
                      // status: null,
                      // statusLabel: "All",
                      page: 1,
                    });
                  }}
                  className="filter-field"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-personnel_id-input"
                className="label-on-input z-10"
              >
                Personnel <span className="text-red-600">*</span>
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={personnelIdOptions}
                  name="personnel_id"
                  id="personnel_id"
                  instanceId="personnel_id"
                  value={{
                    label: personnelId.label,
                    value: personnelId.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("personnel_id", selected.value);
                    setPersonnelId(selected);
                    //setStatus({ label: "All", value: "all" });

                    setMultipleQueryParams({
                      personnelId: selected.value,
                      personnelLabel: selected.label,
                      // status: null,
                      // statusLabel: "All",
                      page: 1,
                    });
                    // if (selected.value != "all") {
                    //   props.setQueryFilters({
                    //     page: 1,
                    //     status: null,
                    //     programId:
                    //       clientName.value == "all" ? null : clientName.value,
                    //     personnelId: selected.value,
                    //   });
                    // } else {
                    //   props.setQueryFilters({
                    //     page: 1,
                    //     status: null,
                    //     programId:
                    //       clientName.value == "all" ? null : clientName.value,
                    //     personnelId: null,
                    //   });
                    // }
                  }}
                  className="filter-field"
                />

                {formik.touched.personnel_id && formik.errors.personnel_id ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.personnel_id}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-status-input"
                className="label-on-input z-10"
              >
                Status <span className="text-red-600">*</span>
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={statusOptions}
                  name="status"
                  id="status"
                  instanceId="status"
                  value={{
                    label: status.label,
                    value: status.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("status", selected.value);
                    setStatus(selected);
                    setMultipleQueryParams({
                      status: selected.value,
                      statusLabel: selected.label,
                      page: 1,
                    });
                    // if (selected.value != "all") {
                    //   props.setQueryFilters({
                    //     page: 1,
                    //     programId:
                    //       clientName.value == "all" ? null : clientName.value,
                    //     personnelId:
                    //       personnelId.value == "all" ? null : personnelId.value,
                    //     status: selected.value,
                    //   });
                    // } else {
                    //   props.setQueryFilters({
                    //     page: 1,
                    //     programId:
                    //       clientName.value == "all" ? null : clientName.value,
                    //     personnelId:
                    //       personnelId.value == "all" ? null : personnelId.value,
                    //     status: null,
                    //   });
                    // }
                  }}
                  className="filter-field capitalize"
                />

                {formik.touched.status && formik.errors.status ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.status}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
