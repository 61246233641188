import * as Yup from "yup";

import { Layout, ModalAlert } from "@components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import withAuth from "../../middlewares/withAuth";
import CurrencyInput from "react-currency-input-field";
import Filter from "./filter";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import formatNumber from "../../utils/formatNumber";
import moment from "moment";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import useQueryParams from "../../utils/query_params";

const validationSchema = Yup.object({
  cash_advance_reference: Yup.string().required("Required"),
  cash_advance_date: Yup.string().required("Required"),
});

const liquidateSchema = Yup.object({
  liquidate_date: Yup.string().required("Required"),
  liquidate_amount: Yup.number().required("Required"),
  liquidate_reference: Yup.string().required("Required"),
});

const ADMIN_ALL_ADVANCES_QUERY = gql`
  query (
    $page: Int!
    $pageSize: Int!
    $personnelId: String
    $programId: String
    $clientId: String
    $status: String
  ) {
    adminAllAdvances(
      page: $page
      pageSize: $pageSize
      personnelId: $personnelId
      programId: $programId
      clientId: $clientId
      status: $status
    ) {
      entries {
        program {
          id
          programName
          clientName
        }
        advanceType {
          id
          referenceCode
          referenceDescription
          referenceType
        }
        liquidatedCost
        liquidatedDate
        releasedDate
        cashAdvanceReference
        cost
        id
        personnel {
          id
          firstName
          lastName
        }
        status
      }
      pageNumber
      pageSize
      totalPages
      totalEntries
    }
  }
`;

const TAG_CASH_ADVANCE_AS_PAID_MUTATION = gql`
  mutation (
    $cashAdvanceReference: String!
    $id: String!
    $status: String!
    $releasedDate: String!
  ) {
    tagCashAdvanceAsPaid(
      cashAdvanceReference: $cashAdvanceReference
      id: $id
      status: $status
      releasedDate: $releasedDate
    ) {
      advance {
        advanceType {
          id
        }
        releasedDate
        cashAdvanceReference
        cost
        id
        personnel {
          id
        }
      }
    }
  }
`;

const TAG_CASH_ADVANCE_AS_LIQUIDATED_MUTATION = gql`
  mutation (
    $cashAdvanceReference: String!
    $id: String!
    $status: String!
    $liquidatedCost: Float!
    $liquidatedDate: String!
  ) {
    tagCashAdvanceAsLiquidated(
      cashAdvanceReference: $cashAdvanceReference
      id: $id
      status: $status
      liquidatedCost: $liquidatedCost
      liquidatedDate: $liquidatedDate
    ) {
      advance {
        advanceType {
          id
        }
        releasedDate
        liquidatedCost
        liquidatedDate
        cashAdvanceReference
        cost
        id
        personnel {
          id
        }
      }
    }
  }
`;

function CashAdvance() {
  const [tag_cash_advance_as_paid, { loading: mutationLoading }] = useMutation(
    TAG_CASH_ADVANCE_AS_PAID_MUTATION,
    {
      onCompleted: (data) => {
        if (data && data.tagCashAdvanceAsPaid) {
          refetch({
            page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
            pageSize: getQueryParam("pageSize")
              ? parseInt(getQueryParam("pageSize"))
              : 10,
            programId: getQueryParamOrNull("programId"),
            clientId: getQueryParamOrNull("clientId"),
            personnelId: getQueryParamOrNull("personnelId"),
            status: getQueryParamOrNull("status"),
          }).then(() => {
            setShowModal(false);
          });
        } else {
          console.error("error marking advance as paid");
        }
      },
      onerror: (error) => {
        console.error("error marking advance as paid:", error);
      },
    }
  );

  const [tag_cash_advance_as_liquidate, { loading: liquidatemutationLoading }] =
    useMutation(TAG_CASH_ADVANCE_AS_LIQUIDATED_MUTATION, {
      onCompleted: (data) => {
        if (data && data.tagCashAdvanceAsLiquidated) {
          refetch({
            page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
            pageSize: getQueryParam("pageSize")
              ? parseInt(getQueryParam("pageSize"))
              : 10,
            programId: getQueryParamOrNull("programId"),
            personnelId: getQueryParamOrNull("personnelId"),
            status: getQueryParamOrNull("status"),
          }).then(() => {
            setShowLiquidateModal(false);
          });
        } else {
          console.error("Error marking advance as liquidate");
        }
      },
      onError: (error) => {
        console.error("Error marking advance as liquidate:", error);
      },
    });

  const location = useLocation();
  const { getQueryParam, setQueryParam, setMultipleQueryParams } =
    useQueryParams();
  const [page, setPage] = useState(
    getQueryParam("page") ? parseInt(getQueryParam("page")) : 1
  );
  const [pageSize, setPageSize] = useState(
    getQueryParam("pageSize") ? parseInt(getQueryParam("pageSize")) : 10
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [queryFilters, setQueryFilters] = useState({ page: 1 });
  const [showLiquidateModal, setShowLiquidateModal] = useState(false);
  const [liquidateId, setLiquidateId] = useState("");
  const [selectedCashAdvance, setSelectedCashAdvance] = useState(null);

  const getQueryParamOrNull = (params) => {
    const value = getQueryParam(params);
    return value === "all" ? null : value;
  };

  const { loading, error, data, refetch } = useQuery(ADMIN_ALL_ADVANCES_QUERY, {
    variables: {
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
      programId: getQueryParamOrNull("programId"),
      clientId: getQueryParamOrNull("clientId"),
      personnelId: getQueryParamOrNull("personnelId"),
      status: getQueryParamOrNull("status"),
    },
  });

  // useEffect(() => {
  //   refetch(queryFilters);
  // }, [ queryFilters, refetch ] );

  useEffect(() => {
    if (getQueryParam("page")) {
      setPage(parseInt(getQueryParam("page")));
    }
    refetch({
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
      programId: getQueryParamOrNull("programId"),
      personnelId: getQueryParamOrNull("personnelId"),
      status: getQueryParamOrNull("status"),
    });
  }, [location.search]);

  const handlePageClick = (e) => {
    const newPage = e.selected + 1;
    setPage(newPage);
    setQueryParam("page", parseInt(newPage));
    // refetch({ page: newPage, pageSize });
  };

  const formik = useFormik({
    initialValues: {
      cash_advance_reference: "",
      cash_advance_date: moment().format("YYYY-MM-DD"), // Set current date as default
    },
    validationSchema,
    onSubmit: (values) => {
      setShowModal(false);
      tag_cash_advance_as_paid({
        variables: {
          id: selectedId,
          cashAdvanceReference: values.cash_advance_reference,
          status: "released",
          releasedDate: values.cash_advance_date,
        },
      });
    },
  });

  const liquidateFormik = useFormik({
    initialValues: {
      liquidate_date: moment().format("YYYY-MM-DD"), // Set current date as default
      liquidate_amount:
        selectedCashAdvance?.liquidatedCost ?? selectedCashAdvance?.cost, // Use liquidatedCost if it exists, otherwise fall back to cost
      liquidate_reference: selectedCashAdvance?.cashAdvanceReference,
    },
    validationSchema: liquidateSchema,
    enableReinitialize: true,
  });

  const handleTagCashAdvance = (status, values) => {
    setShowLiquidateModal(false);
    tag_cash_advance_as_liquidate({
      variables: {
        id: liquidateId,
        cashAdvanceReference: values.liquidate_reference,
        status, // Use the status parameter here
        liquidatedCost: parseFloat(values.liquidate_amount), // Convert amount to Float
        liquidatedDate: values.liquidate_date,
      },
    });
  };

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.adminAllAdvances.pageNumber
    ? (data.adminAllAdvances.pageNumber - 1) * data.adminAllAdvances.pageSize +
      1
    : 0;

  const getFullName = (personnel) => {
    if (!personnel || !personnel.firstName || !personnel.lastName) {
      return "";
    }
    return `${personnel.firstName} ${personnel.lastName}`;
  };

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Cash Advance
              </h1>
              <p className="mt-2 text-xs text-gray-700">
                A list of All the Cash Advances
              </p>
            </div>
          </div>

          <div>
            <Filter setQueryFilters={setQueryFilters} />
          </div>
        </div>
        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="overflow-auto lg:overflow-hidden ">
              <table className="w-full text-left">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="relative isolate process-th w-fit"
                    >
                      Program
                      <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                      <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                    </th>
                    {/* <th
                      scope="col"
                      className="process-th"
                    >
                      Client
                      <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                      <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                    </th> */}
                    <th scope="col" className="process-th min-w-36 w-36">
                      Personnel
                      <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                      <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                    </th>
                    <th scope="col" className="process-th min-w-24 w-24">
                      Type
                    </th>
                    {/* <th
                      scope="col"
                      className="process-th"
                    >
                      Released Date
                    </th>
                    <th
                      scope="col"
                      className="process-th"
                    >
                      Reference
                    </th> */}
                    <th scope="col" className="process-th min-w-40 w-40">
                      Amount
                    </th>
                    {/* <th
                      scope="col"
                      className="process-th"
                    >
                      Liquidated
                    </th> */}
                    {/* <th
                      scope="col"
                      className="process-th"
                    >
                      Status
                    </th> */}
                    <th
                      scope="col"
                      className="process-th !text-left !pl-3 min-w-48"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {mockResponse.adminAllAdvances.entries.map((advance) => ( */}

                  {data.adminAllAdvances.entries.map((advance) => (
                    <tr
                      id={advance.id}
                      key={advance.id}
                      className="cursor-pointer hover:bg-gray-50"
                    >
                      <td
                        // onClick={() => navigate(`/programs/${advance.id}`)}
                        className="relative process-results"
                      >
                        {advance?.program.programName}
                        <span className="text-xs">
                          <br />
                          {advance?.program.clientName}
                        </span>
                        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                        <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                      </td>
                      {/* <td
                        // onClick={() => navigate(`/programs/${advance.id}`)}
                        className="relative process-results"
                      >
                        {advance?.program.clientName}
                        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                        <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                      </td> */}
                      <td
                        // onClick={() => navigate(`/programs/${advance.id}`)}
                        className="relative process-results"
                      >
                        {getFullName(advance?.personnel)}
                        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                        <div className="absolute bottom-0 h-px w-screen bg-gray-100" />
                      </td>
                      <td
                        // onClick={() => programIdnavigate(`/advances/${advance.id}`)}
                        className="process-results"
                      >
                        {advance?.advanceType?.referenceDescription}
                      </td>
                      {/* <td
                        // onClick={() => navigate(`/advances/${advance.id}`)}
                        className="process-results"
                      >
                        {advance.releasedDate
                          ? moment(advance.releasedDate).format("YYYY-MMM-DD")
                          : "Not Released"}
                      </td>
                      <td
                        // onClick={() => navigate(`/advances/${advance.id}`)}
                        className="process-results"
                      >
                        {advance.cashAdvanceReference}
                      </td> */}
                      <td
                        // onClick={() => navigate(`/advances/${advance.id}`)}
                        className="process-results !pl-3"
                      >
                        {advance.status === "released" ||
                        advance.status === "liquidated" ? (
                          <>
                            Used {formatNumber(advance.liquidatedCost)}
                            <span className="text-xs">
                              <br />
                              out of {formatNumber(advance.cost)}
                            </span>
                          </>
                        ) : (
                          <>Available: {formatNumber(advance.cost)}</>
                        )}
                      </td>
                      {/* <td
                        // onClick={() => navigate(`/advances/${advance.id}`)}
                        className="process-results"
                      >
                        {formatNumber(advance.liquidatedCost)}
                      </td> */}
                      {/* <td
                        // onClick={() => navigate(`/advances/${advance.id}`)}
                        className="process-results text-center"
                      >
                        {advance.status === "released" ||
                        advance.status === "liquidated" ? (
                          <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {advance.status}
                          </span>
                        ) : (
                          <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                            Pending
                          </span>
                        )}
                      </td> */}
                      <td className="process-results !pl-3">
                        {(advance.status === "pending" ||
                          advance.status === "unpaid" ||
                          advance.status === "") && (
                          <button
                            type="button"
                            onClick={() => {
                              if (!advance.personnel) {
                                alert(
                                  "Error: Personnel information is missing."
                                ); // Display an error message
                                return; // Exit the function
                              }

                              setSelectedId(advance.id);
                              setShowModal(true);
                            }}
                            className={`block rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm ${
                              mutationLoading && advance.id === selectedId
                                ? "bg-gray-500 cursor-not-allowed"
                                : "bg-green-600 hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            }`}
                            disabled={
                              mutationLoading && advance.id === selectedId
                            }
                          >
                            Release
                          </button>
                        )}
                        {advance.status === "released" && (
                          <>
                            <button
                              type="button"
                              onClick={() => {
                                // This probably won't be triggered since record cannot be touched after Release.
                                if (!advance.personnel) {
                                  alert(
                                    "Error: Personnel information is missing."
                                  ); // Display an error message
                                  return; // Exit the function
                                }

                                setLiquidateId(advance.id);
                                setShowLiquidateModal(true);
                                setSelectedCashAdvance(advance);
                              }}
                              className={`block rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm ${
                                liquidatemutationLoading &&
                                advance.id === liquidateId
                                  ? "bg-gray-500 cursor-not-allowed"
                                  : "bg-red-600 hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                              }`}
                              disabled={
                                liquidatemutationLoading &&
                                advance.id === liquidateId
                              }
                            >
                              Liquidate
                            </button>
                            <span className="text-xs">
                              Released{" "}
                              {moment(advance.releasedDate).format(
                                "YYYY-MMM-DD"
                              )}
                            </span>
                          </>
                        )}
                        {advance.status === "liquidated" && (
                          <>
                            Liquidated and Closed
                            <span className="text-xs">
                              <br />
                              {moment(advance.liquidatedDate).format(
                                "YYYY-MMM-DD"
                              )}
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {data && (
              <div className="py-4 flex flex-row items-center justify-between mt-6">
                <div className="flex flex-wrap items-center justify-between text-sm text-gray-700">
                  <div className="flex items-center space-x-2">
                    <span>
                      Showing{" "}
                      <span className="font-medium">{startOfResults}</span> to
                    </span>
                    <label htmlFor="pageSize" className="sr-only">
                      Results per page:
                    </label>
                    <select
                      id="pageSize"
                      name="pageSize"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                        setQueryParam("pageSize", parseInt(e.target.value, 10));
                        // refetch({
                        //   page,
                        //   pageSize: parseInt(e.target.value, 10),
                        // });
                      }}
                      className="rounded-md bg-white py-1.5 px-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm"
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={1000}>1000</option>
                    </select>
                    <span>
                      of{" "}
                      <span className="font-medium">
                        {data.adminAllAdvances.totalEntries}
                      </span>{" "}
                      results
                    </span>
                  </div>
                </div>

                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <span>
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5 ml-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  }
                  previousLabel={
                    <span>
                      <svg
                        className="h-5 w-5 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={data.adminAllAdvances.totalPages} // Total number of pages
                  forcePage={page - 1} // Sync with the current page (zero-indexed)
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                  pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                  previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                />
              </div>
            )}
          </div>
        </div>

        {showModal && (
          <>
            <ModalAlert open={showModal} setOpen={setShowModal}>
              <form onSubmit={formik.handleSubmit}>
                {/* Modal content */}
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Release Cash Advance
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    Please enter the reference number of the cash advance you
                    wish to released.
                  </p>
                  <div className="mt-3">
                    <label
                      htmlFor="cash_advance_date"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Released Date
                    </label>

                    <div className="mt-2 space-y-2">
                      <input
                        id="cash_advance_date"
                        name="cash_advance_date"
                        type="date"
                        className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        max={currentDate}
                        value={formik.values.cash_advance_date}
                        tabIndex="4"
                      />
                      {formik.touched.cash_advance_date &&
                      formik.errors.cash_advance_date ? (
                        <div className="text-red-600 text-sm">
                          {formik.errors.cash_advance_date}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="mt-4">
                    <label
                      htmlFor="cash_advance_reference"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Cash Advance Reference Number
                    </label>
                    {/* <input
                      id="cash_advance_reference"
                      name="cash_advance_reference"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cash_advance_reference}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      tabIndex="1"
                      maxLength={255}
                    />
                    {formik.touched.cash_advance_reference &&
                      formik.errors.cash_advance_reference && (
                        <div className="text-red-600 text-sm">
                          {formik.errors.cash_advance_reference}
                        </div>
                      )} */}
                  </div>
                  <div className="mt-2 space-y-2">
                    <textarea
                      rows={3}
                      id="cash_advance_reference"
                      name="cash_advance_reference"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cash_advance_reference}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      tabIndex="1"
                    />
                    {formik.touched.cash_advance_reference &&
                    formik.errors.cash_advance_reference ? (
                      <div className="text-red-600 text-sm">
                        {formik.errors.cash_advance_reference}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* Modal actions */}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    tabIndex="2"
                  >
                    {mutationLoading ? (
                      <svg
                        className="animate-spin h-5 w-5 mr-3 ..."
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "Confirm"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setShowModal(false)}
                    tabIndex="3"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </ModalAlert>
          </>
        )}

        {showLiquidateModal && (
          <>
            <ModalAlert
              open={showLiquidateModal}
              setOpen={setShowLiquidateModal}
            >
              <form onSubmit={liquidateFormik.handleSubmit}>
                {/* Modal content */}
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Liquidate Cash Advance
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    Please enter the details of the cash advance you wish to
                    liquidate.
                  </p>
                  <div className="mt-4">
                    <label
                      htmlFor="liquidate_date"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Date
                    </label>
                    <input
                      id="liquidate_date"
                      name="liquidate_date"
                      type="date"
                      onChange={liquidateFormik.handleChange}
                      onBlur={liquidateFormik.handleBlur}
                      max={currentDate}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400"
                      value={liquidateFormik.values.liquidate_date}
                      tabIndex="5"
                    />
                    {liquidateFormik.touched.liquidate_date &&
                      liquidateFormik.errors.liquidate_date && (
                        <div className="text-red-600 text-sm">
                          {liquidateFormik.errors.liquidate_date}
                        </div>
                      )}
                  </div>

                  <div className="mt-3">
                    <label
                      htmlFor="liquidate_amount"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Amount
                    </label>
                    <CurrencyInput
                      id="liquidate_amount"
                      name="liquidate_amount"
                      value={liquidateFormik.values.liquidate_amount}
                      decimalsLimit={2}
                      onValueChange={(value) =>
                        liquidateFormik.setFieldValue("liquidate_amount", value)
                      }
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      tabIndex="1"
                    />
                    {liquidateFormik.touched.liquidate_amount &&
                      liquidateFormik.errors.liquidate_amount && (
                        <div className="text-red-600 text-sm">
                          {liquidateFormik.errors.liquidate_amount}
                        </div>
                      )}
                  </div>

                  <div className="mt-3">
                    <label
                      htmlFor="liquidate_reference"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Reference
                    </label>
                    {/* <input
                      id="liquidate_reference"
                      name="liquidate_reference"
                      type="text"
                      onChange={liquidateFormik.handleChange}
                      onBlur={liquidateFormik.handleBlur}
                      value={liquidateFormik.values.liquidate_reference || ""}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      tabIndex="2"
                      maxLength={255}
                    />
                    {liquidateFormik.touched.liquidate_reference &&
                      liquidateFormik.errors.liquidate_reference && (
                        <div className="text-red-600 text-sm">
                          {liquidateFormik.errors.liquidate_reference}
                        </div>
                      )} */}
                  </div>

                  <div className="mt-2 space-y-2">
                    <textarea
                      rows={3}
                      id="liquidate_reference"
                      name="liquidate_reference"
                      type="text"
                      onChange={liquidateFormik.handleChange}
                      onBlur={liquidateFormik.handleBlur}
                      value={liquidateFormik.values.liquidate_reference}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      tabIndex="2"
                    />
                    {liquidateFormik.touched.liquidate_reference &&
                    liquidateFormik.errors.liquidate_reference ? (
                      <div className="text-red-600 text-sm">
                        {liquidateFormik.errors.liquidate_reference}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* Modal actions */}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <div className="flex space-x-3">
                    {/* Cancel Button */}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      tabIndex="4"
                      onClick={() => setShowLiquidateModal(false)}
                    >
                      Cancel
                    </button>
                    {/* Liquidate Button */}
                    <button
                      type="button"
                      onClick={() =>
                        handleTagCashAdvance("released", {
                          liquidate_reference:
                            liquidateFormik.values.liquidate_reference,
                          liquidate_amount:
                            liquidateFormik.values.liquidate_amount,
                          liquidate_date: liquidateFormik.values.liquidate_date,
                        })
                      }
                      className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:w-auto"
                      tabIndex="2"
                    >
                      {mutationLoading ? (
                        <svg
                          className="animate-spin h-5 w-5 mr-3 ..."
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        "Liquidate"
                      )}
                    </button>
                    {/* Liquidate and Close Button */}
                    <button
                      type="button"
                      onClick={() =>
                        handleTagCashAdvance("liquidated", {
                          liquidate_reference:
                            liquidateFormik.values.liquidate_reference,
                          liquidate_amount:
                            liquidateFormik.values.liquidate_amount,
                          liquidate_date: liquidateFormik.values.liquidate_date,
                        })
                      }
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:w-auto"
                      tabIndex="2"
                    >
                      {mutationLoading ? (
                        <svg
                          className="animate-spin h-5 w-5 mr-3 ..."
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        "Liquidate and Close"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </ModalAlert>
          </>
        )}
      </div>
    </Layout>
  );
}

export default withAuth(CashAdvance, [0, 1]);
