import * as Yup from "yup";
import withAuth from "../../middlewares/withAuth";
import { Layout, ModalAlert } from "@components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react"; // Import useState hook

import CurrencyInput from "react-currency-input-field";
import Filter from "./filter";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import formatNumber from "../../utils/formatNumber";
import moment from "moment";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import useQueryParams from "../../utils/query_params";

const validationSchema = Yup.object({
  paidDate: Yup.date().required("Required"),
  salesAmount: Yup.string().required("Required"),
  salesTaxAmount: Yup.string().required("Required"),
});

const ADMIN_SALES_QUERY = gql`
  query (
    $page: Int!
    $pageSize: Int!
    $personnelId: String
    $program: String
    $client: String
    $status: String
  ) {
    adminSales(
      page: $page
      pageSize: $pageSize
      personnelId: $personnelId
      program: $program
      client: $client
      status: $status
    ) {
      entries {
        clientName
        commission
        commissionRate
        contractPrice
        sellingPrice
        date
        id
        name
        netAmount
        programName
        salesType
        status
        tax
        taxAmount
        paidDate
      }
      pageNumber
      pageSize
      totalPages
      totalEntries
    }
  }
`;

const SALE_MARK_AS_PAID_MUTATION = gql`
  mutation (
    $id: String!
    $paidDate: String!
    $salesAmount: Float
    $salesTaxAmount: Float
  ) {
    saleMarkAsPaid(
      id: $id
      paidDate: $paidDate
      salesAmount: $salesAmount
      salesTaxAmount: $salesTaxAmount
    ) {
      sale {
        id
      }
    }
  }
`;

function SaleComissionPayables() {
  const [sale_mark_as_paid] = useMutation(SALE_MARK_AS_PAID_MUTATION, {
    onCompleted: (data) => {
      if (data && data.saleMarkAsPaid) {
        refetch();
      } else {
        console.error("Error marking advance as paid:", error);
      }
    },
    onError: (error) => {
      console.error("Error marking advance as paid:", error);
    },
  });

  const location = useLocation();
  const getQueryParamOrNull = (params) => {
    const value = getQueryParam(params);
    return value === "all" ? null : value;
  };

  const { getQueryParam, setQueryParam, setMultipleQueryParams } =
    useQueryParams();
  const [page, setPage] = useState(
    getQueryParam("page") ? parseInt(getQueryParam("page")) : 1
  );
  const [pageSize, setPageSize] = useState(
    getQueryParam("pageSize") ? parseInt(getQueryParam("pageSize")) : 10
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSeletedId] = useState("");
  const [queryFilters, setQueryFilters] = useState({ page: 1 });

  const { loading, error, data, refetch } = useQuery(ADMIN_SALES_QUERY, {
    variables: {
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
      client: getQueryParamOrNull("client"),
      program: getQueryParamOrNull("program"),
      personnelId: getQueryParamOrNull("personnel"),
      status: getQueryParamOrNull("status") || "all",
    },
  });

  const formik = useFormik({
    initialValues: {
      paidDate: new Date().toISOString().split("T")[0],
      salesAmount: "",
      salesTaxAmount: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (showModal && selectedId) {
        sale_mark_as_paid({
          variables: {
            id: selectedId,
            paidDate: values.paidDate,
            salesAmount: parseFloat(values.salesAmount),
            salesTaxAmount: parseFloat(values.salesTaxAmount),
          },
        });
        setShowModal(false);
        refetch({
          page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
          pageSize: getQueryParam("pageSize")
            ? parseInt(getQueryParam("pageSize"))
            : 10,
          client: getQueryParamOrNull("client"),
          program: getQueryParamOrNull("program"),
          personnelId: getQueryParamOrNull("personnel"),
          status: getQueryParamOrNull("status"),
        });
      }
    },
  });

  useEffect(() => {
    if (getQueryParam("page")) {
      setPage(parseInt(getQueryParam("page")));
    }
    refetch({
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
      client: getQueryParamOrNull("client"),
      program: getQueryParamOrNull("program"),
      personnelId: getQueryParamOrNull("personnel"),
      status: getQueryParamOrNull("status"),
    });
  }, [location.search]);

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    setQueryParam("page", e.selected + 1);
  };

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.adminSales.pageNumber
    ? (data.adminSales.pageNumber - 1) * data.adminSales.pageSize + 1
    : 0;

  const currentDate = new Date().toISOString().split("T")[0];

  const handleCancel = () => {
    formik.resetForm();
    setShowModal(false);
  };

  const handleDownloadCSV = async () => {
    try {
      // Await the first refetch to ensure it completes before the download
      const { data } = await refetch({
        page: 1, // Bypass pagination to get all data
        pageSize: 10000, // Or a very large number to fetch everything at once
      });

      // Check if data is available, then proceed with download
      if (data && data.adminSales.entries) {
        downloadCSV(data.adminSales.entries);
      }

      // Now that the CSV is downloaded, perform the second refetch
      const secondRefetch = await refetch({
        page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
        pageSize: getQueryParam("pageSize")
          ? parseInt(getQueryParam("pageSize"))
          : 10,
      });

      return secondRefetch;
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const downloadCSV = (csvdata) => {
    // Helper function to sanitize fields (removes commas)
    const sanitizeField = (value) => {
      if (typeof value === "string") {
        return value.replace(/,/g, ""); // Remove commas from strings
      }
      return value;
    };

    // Helper function to generate and download CSV
    const createAndDownloadCSV = (filename, data) => {
      const csvRows = [
        Object.keys(data[0]).join(","), // Headers
        ...data.map((row) =>
          Object.values(row)
            .map((value) => sanitizeField(value))
            .join(",")
        ), // Data rows
      ].join("\n");

      const blob = new Blob([csvRows], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
    };

    // Prepare data for PayablesDetailed (Ordered by lastName, firstName, rosterDate)
    const filePayablesDetailed = csvdata
      .map((entry) => ({
        //lastName: entry.personnel.lastName,
        //firstName: entry.personnel.firstName,
        //rosterDate: entry.rosterDate,
        fullName: entry.name,
        clientName: entry.clientName,
        programName: entry.programName,
        role: entry.salesType,
        commission: entry.commission,
        commisionTax: entry.taxAmount,
        //payout: entry.rate - entry.rateTaxAmount,
        commissionPayout: entry.netAmount,
      }))
      .sort(
        (a, b) => a.fullName.localeCompare(b.fullName)
        //new Date(a.rosterDate) - new Date(b.rosterDate)
      );

    // Prepare data for PayablesGrouped (Aggregated totals, ordered by lastName, firstName)
    const personnelAggregates = {};
    csvdata.forEach((entry) => {
      //const key = `${entry.personnel.lastName}|${entry.personnel.firstName}`;
      const key = `${entry.name}`;
      if (!personnelAggregates[key]) {
        personnelAggregates[key] = {
          //lastName: entry.personnel.lastName,
          //firstName: entry.personnel.firstName,
          fullName: entry.name,
          totalCommission: 0,
          totalCommisionTax: 0,
          totalCommissionPayout: 0,
        };
      }
      // personnelAggregates[key].totalRate += entry.rate;
      // personnelAggregates[key].totalRateTaxAmount += entry.rateTaxAmount;
      // personnelAggregates[key].totalPayout += entry.rate - entry.rateTaxAmount;
      personnelAggregates[key].totalCommission += entry.commission;
      personnelAggregates[key].totalCommisionTax += entry.taxAmount;
      personnelAggregates[key].totalCommissionPayout += entry.netAmount;
    });

    const filePayablesGrouped = Object.values(personnelAggregates)
      .map(
        ({
          // lastName,
          // firstName,
          fullName,
          totalCommission,
          totalCommisionTax,
          totalCommissionPayout,
        }) => ({
          // lastName,
          // firstName,
          fullName,
          totalCommission,
          totalCommisionTax,
          totalCommissionPayout,
        })
      )
      .sort((a, b) => a.fullName.localeCompare(b.fullName));

    // Download both files
    createAndDownloadCSV("filePayablesDetailed.csv", filePayablesDetailed);

    setTimeout(() => {
      createAndDownloadCSV("filePayablesGrouped.csv", filePayablesGrouped);
    }, 2500); // Delay of 2.5 seconds
  };

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Sale Commission Payables
              </h1>
              <p className="mt-2 text-xs text-gray-700">
                A list of All the Sale Commission Payables
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleDownloadCSV}
              >
                Download
              </button>
            </div>
          </div>
          <Filter
            setQueryFilters={setQueryFilters}
            setPage={setPage}
            filter={refetch}
          />
        </div>
        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="w-full text-left">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="relative isolate process-th !text-left min-w-64"
                  >
                    Program
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                  </th>
                  <th scope="col" className="process-th !text-left min-w-36">
                    Personnel
                  </th>
                  <th scope="col" className="process-th min-w-12">
                    Type
                  </th>
                  <th scope="col" className="process-th">
                    Selling Price
                  </th>
                  {/* <th scope="col" className="process-th" >
                    Commission Rate
                  </th> */}
                  <th scope="col" className="process-th min-w-24">
                    Commission
                  </th>
                  <th scope="col" className="process-th min-w-16">
                    Tax %
                  </th>
                  {/* <th scope="col" className="process-th" >
                    Tax Amount
                  </th> */}
                  <th scope="col" className="process-th min-w-24">
                    Net Payout
                  </th>
                  <th scope="col" className="process-th min-w-12">
                    Status
                  </th>
                  {/* <th scope="col" className="process-th" >
                    Paid Date
                  </th> */}
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.adminSales.entries.map((item) => (
                  <tr
                    id={item.id}
                    key={item.id}
                    className="cursor-pointer hover:bg-gray-50"
                  >
                    {/* <td className="process-results relative py-4 pr-3 text-xs font-medium text-gray-900 whitespace-nowrap align-top">
                      {moment(item.date).format("YYYY-MMM-DD")}
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td> */}
                    <td className="process-results">
                      {item?.programName}
                      <span className="text-xs">
                        <br />
                        {item?.clientName}
                      </span>
                    </td>
                    <td className="process-results">{item?.name}</td>
                    <td className="process-results !text-center">
                      {item?.salesType === "Sales Person"
                        ? "Sales"
                        : item?.salesType === "Referral Person"
                        ? "Referral"
                        : item?.salesType === "Sales Support"
                        ? "Support"
                        : "Unknown"}
                    </td>
                    <td className="process-results text-right">
                      {formatNumber(item?.sellingPrice)}
                    </td>
                    {/* <td className="process-results">
                      {item?.commissionRate}%
                    </td> */}
                    <td className="process-results text-right">
                      {formatNumber(item?.commission)}
                      <span className="text-xs">
                        <br />
                        {formatNumber(item?.commissionRate)} %
                      </span>
                    </td>
                    <td className="process-results text-right">
                      {formatNumber(item.taxAmount)}
                      <span className="text-xs">
                        <br />
                        {!item.paidDate
                          ? formatNumber(item?.tax)
                          : formatNumber(
                              (item.taxAmount / item?.commission) * 100
                            )}{" "}
                        %
                      </span>
                    </td>
                    {/* <td className="process-results">
                      {formatNumber(item.taxAmount)}
                    </td> */}
                    <td className="process-results text-right">
                      {formatNumber(item.netAmount)}
                    </td>
                    <td className="process-results">
                      {item.status.toLowerCase() === "open" ||
                      item.status.toLowerCase() === "ready to pay" ? (
                        <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {item.status}
                        </span>
                      ) : (
                        <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          COMPLETED
                        </span>
                      )}
                    </td>
                    {/* <td className="process-results">
                      {item.paidDate && moment(item.paidDate).format("YYYY-MMM-DD")}
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td> */}
                    <td className="process-results text-left">
                      {item.status.toLowerCase() === "ready to pay" &&
                        !item.paidDate && (
                          <button
                            type="button"
                            onClick={() => {
                              setSeletedId(item.id);
                              setShowModal(true);
                              formik.setFieldValue(
                                "paidDate",
                                new Date().toISOString().split("T")[0]
                              );
                              formik.setFieldValue(
                                "salesAmount",
                                item.commission
                              );
                              formik.setFieldValue(
                                "salesTaxAmount",
                                item.taxAmount
                              );
                            }}
                            className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 whitespace-nowrap"
                          >
                            Mark as Paid
                          </button>
                        )}
                      {item.status.toLowerCase() === "completed" &&
                        item.paidDate && (
                          <>
                            Paid:
                            <span className="text-xs">
                              <br />
                              {moment(item.paidDate).format("YYYY-MMM-DD")}
                            </span>
                          </>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data && (
              <div className="py-4 flex flex-row items-center justify-between mt-6">
                <div className="flex flex-wrap items-center justify-between text-sm text-gray-700">
                  <div className="flex items-center space-x-2">
                    <span>
                      Showing{" "}
                      <span className="font-medium">{startOfResults}</span> to
                    </span>
                    <label htmlFor="pageSize" className="sr-only">
                      Results per page:
                    </label>
                    <select
                      id="pageSize"
                      name="pageSize"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                        setQueryParam("pageSize", parseInt(e.target.value, 10));
                      }}
                      className="rounded-md bg-white py-1.5 px-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm"
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={1000}>1000</option>
                    </select>
                    <span>
                      of{" "}
                      <span className="font-medium">
                        {data.adminSales.totalEntries}
                      </span>{" "}
                      results
                    </span>
                  </div>
                </div>

                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <span>
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5 ml-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  }
                  previousLabel={
                    <span>
                      <svg
                        className="h-5 w-5 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={data.adminSales.totalPages} // Total number of pages
                  forcePage={page - 1} // Sync with the current page (zero-indexed)
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                  pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                  previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                />
              </div>
            )}
          </div>
        </div>

        {showModal && (
          <>
            <ModalAlert open={showModal} setOpen={setShowModal}>
              <form onSubmit={formik.handleSubmit}>
                {/* Modal content */}
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Mark as Paid
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    Please enter the date to mark as paid.
                  </p>
                  <div className="mt-4">
                    <label
                      htmlFor="paidDate"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    ></label>
                    <input
                      id="paidDate"
                      name="paidDate"
                      type="date"
                      // defaultValue={formik.values.paidDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.paidDate}
                      max={currentDate}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {formik.touched.paidDate && formik.errors.paidDate && (
                      <div className="text-red-600 text-sm">
                        {formik.errors.paidDate}
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="salesAmount"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Sales Amount
                    </label>
                    <CurrencyInput
                      id="salesAmount"
                      name="salesAmount"
                      // defaultValue={0.0}
                      decimalsLimit={2}
                      readOnly
                      tabIndex={-1}
                      defaultValue={formik.values.salesAmount}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 cursor-not-allowed focus:outline-none focus:ring-1 select-none"
                    />
                    {formik.touched.salesAmount &&
                      formik.errors.salesAmount && (
                        <div className="text-red-600 text-sm">
                          {formik.errors.salesAmount}
                        </div>
                      )}
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="salesTaxAmount"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Sales tax amount
                    </label>
                    <CurrencyInput
                      id="salesTaxAmount"
                      name="salesTaxAmount"
                      // defaultValue={0.0}
                      decimalsLimit={2}
                      readOnly
                      tabIndex={-1}
                      defaultValue={formik.values.salesTaxAmount}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 cursor-not-allowed focus:outline-none focus:ring-1 select-none"
                    />
                    {formik.touched.salesTaxAmount &&
                      formik.errors.salesTaxAmount && (
                        <div className="text-red-600 text-sm">
                          {formik.errors.salesTaxAmount}
                        </div>
                      )}
                  </div>
                </div>
                {/* Modal actions */}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </ModalAlert>
          </>
        )}
      </div>
    </Layout>
  );
}

export default withAuth(SaleComissionPayables, [0, 1]);
