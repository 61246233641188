import { Layout } from "@components";
import Filter from "./filter";
import List from "./list";
import { useEffect, useState } from "react";
import withAuth from "../../middlewares/withAuth";
function Allowance() {
  const [triggerDownload, setTriggerDownload] = useState(false);

  useEffect(() => {
    if (triggerDownload) {
      // Reset the trigger after the child has had time to process it
      setTimeout(() => {
        setTriggerDownload(false);
      }, 100); // Small delay to ensure the child sees the change
    }
  }, [triggerDownload]);

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Allowance
              </h1>
              <p className="mt-2 text-xs text-gray-700">
                A list of All the Allowances
              </p>
            </div>

            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => setTriggerDownload(true)}
              >
                Download
              </button>
            </div>
          </div>

          <div className="-mt-2">
            <Filter />
          </div>
          <List triggerDownload={triggerDownload} />
        </div>
      </div>
    </Layout>
  );
}

export default withAuth(Allowance, [0, 1]);
