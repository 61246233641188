import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";

import { CalendarIcon } from "@heroicons/react/24/solid";
import { Layout } from "@components";
import Loader from "../../../components/Loader";

const ALLOWANCE_BUDGET_REPORT_QUERY = gql`
  query ($date: String) {
    allowanceBudgetReport(date: $date) {
      status
      allowanceBudgetResult {
        id
        allowancebudget
        forfeited
        referenceDescription
        reimbursed
        remainingbudget
      }
    }
  }
`;

export default function AllowanceBudget() {
  const [selectedDate, setSelectedDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );

  const { data, loading, error, refetch } = useQuery(
    ALLOWANCE_BUDGET_REPORT_QUERY,
    {
      variables: { date: selectedDate },
    }
  );

  if (error) return <p className="text-red-500">Error: {error.message}</p>;

  const rows = data?.allowanceBudgetReport?.allowanceBudgetResult || [];

  const total = rows.reduce(
    (acc, item) => {
      return {
        allowancebudget:
          acc.allowancebudget + (parseFloat(item.allowancebudget) || 0),
        forfeited: acc.forfeited + (parseFloat(item.forfeited) || 0),
        reimbursed: acc.reimbursed + (parseFloat(item.reimbursed) || 0),
        remainingbudget:
          acc.remainingbudget + (parseFloat(item.remainingbudget) || 0),
      };
    },
    {
      allowancebudget: 0,
      forfeited: 0,
      reimbursed: 0,
      remainingbudget: 0,
    }
  );

  const formatNumber = (num) =>
    new Intl.NumberFormat("en-PH", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Allowance Budget
              </h1>
              <p className="mt-2 text-xs text-gray-700">
                A list of All the Allowance Budget
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-12 md:col-span-2 items-start mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="sm:col-span-3">
            <label htmlFor="start_date" className="label-on-input">
              Date
            </label>

            <div className="mt-2 space-y-2">
              <input
                autoFocus
                type="date"
                id="filter-date"
                onChange={(e) => {
                  if (moment(e.target.value, "YYYY-MM-DD", true).isValid()) {
                    setSelectedDate(e.target.value);
                  }
                }}
                // value={selectedDate}
                className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="w-full text-left">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="relative isolate process-th min-w-24"
                  >
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                  </th>
                  <th className="process-th">
                    <p className="text-right">Allowance Budget</p>
                  </th>
                  <th className="process-th">
                    <p className="text-right">Reimbursed</p>
                  </th>
                  <th className="process-th">
                    <p className="text-right">Forfeited</p>
                  </th>
                  <th className="process-th">
                    <p className="text-right">Remaining</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((item) => (
                  <tr key={item.id} className="cursor-pointer hover:bg-gray-50">
                    <td className="relative process-results">
                      <p className="text-sm font-semibold text-gray-800 py-2">
                        {" "}
                        {item.referenceDescription}
                      </p>

                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                    <td className="process-results text-right">
                      <p className="text-sm font-normal text-gray-800 py-2">
                        {formatNumber(item.allowancebudget)}
                      </p>
                    </td>
                    <td className="process-results text-right">
                      <p className="text-sm font-normal text-gray-800 py-2">
                        {formatNumber(item.reimbursed)}
                      </p>
                    </td>
                    <td className="process-results text-right">
                      <p className="text-sm font-normal text-gray-800 py-2">
                        {formatNumber(item.forfeited)}
                      </p>
                    </td>
                    <td className="process-results text-right">
                      <p className="text-sm font-normal text-gray-800 py-2">
                        {formatNumber(item.remainingbudget)}
                      </p>
                    </td>
                  </tr>
                ))}
                {rows.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center py-4 text-gray-500">
                      No data available
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td className="process-results">
                      <p className="text-sm font-semibold text-gray-800 py-2">
                        Total
                      </p>
                    </td>
                    <td className="process-results text-right">
                      <p className="text-sm font-semibold text-gray-800 py-2">
                        {formatNumber(total.allowancebudget)}
                      </p>
                    </td>
                    <td className="process-results text-right">
                      <p className="text-sm font-semibold text-gray-800 py-2">
                        {formatNumber(total.reimbursed)}
                      </p>
                    </td>
                    <td className="process-results text-right">
                      <p className="text-sm font-semibold text-gray-800 py-2">
                        {formatNumber(total.forfeited)}
                      </p>
                    </td>
                    <td className="process-results text-right">
                      <p className="text-sm font-semibold text-gray-800 py-2">
                        {formatNumber(total.remainingbudget)}
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}
