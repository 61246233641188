import * as Yup from "yup";
import withAuth from "../../middlewares/withAuth";
import {
  CheckCircleIcon,
  EyeIcon,
  EyeSlashIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import { Layout } from "@components";
import { useFormik } from "formik";

// GraphQL query and mutation
const GET_USER_INFO = gql`
  query {
    getUserInfo {
      status
      user {
        id
        email
        firstName
        lastName
        accessLevel
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $id: String!
    $email: String
    $firstName: String
    $lastName: String
    $accessLevel: Int
    $password: String
    $confirmPassword: String
  ) {
    updateUser(
      id: $id
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      accessLevel: $accessLevel
      firstName: $firstName
      lastName: $lastName
    ) {
      status
      user {
        id
        email
        firstName
        lastName
        accessLevel
      }
    }
  }
`;

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

function Settings() {
  const { data, loading, error } = useQuery(GET_USER_INFO);
  const [updateUser] = useMutation(UPDATE_USER);
  const [user, setUser] = useState(null);
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Set user data in state when query data is fetched
  useEffect(() => {
    if (data && data.getUserInfo?.user) {
      setUser(data.getUserInfo.user);
    }
  }, [data]);

  useEffect(() => {
    if (user) {
      formik.setValues({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      });
    }
  }, [user]);

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      updateUser({
        variables: {
          id: user.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          accessLevel: parseInt(user.accessLevel),
          password: values.password,
          confirmPassword: values.confirmPassword,
        },
      })
        .then(() => {
          setAlert({
            type: "success",
            message: "Successfully updated user information.",
          });
          formik.resetForm();
          setIsSubmitting(false);

          // Auto-hide success alert after 5 seconds
          setTimeout(() => setAlert({ type: "", message: "" }), 8000);
        })
        .catch((err) => {
          setAlert({ type: "error", message: `Error: ${err.message}` });
          setIsSubmitting(false);
        });
    },
  });
  const handleBeforeUnload = (e) => {
    if (formik.dirty && !isSubmitting) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formik.dirty, isSubmitting]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching user information</p>;

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mb-6">
            <h1 className="text-xl font-semibold leading-6 text-gray-900">
              Settings
            </h1>
            <p className="text-sm font-normal text-gray-500">
              Keep your personal details up to date and manage your account
              information easily.
            </p>
          </div>

          {/* Alert */}
          {alert.message && (
            <div
              className={`rounded-md p-4 mb-6 ${
                alert.type === "success" ? "bg-green-50" : "bg-red-50"
              }`}
            >
              <div className="flex">
                <div className="shrink-0">
                  {alert.type === "success" ? (
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-green-400"
                    />
                  ) : (
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-red-400"
                    />
                  )}
                </div>
                <div className="ml-3">
                  <p
                    className={`text-sm font-medium ${
                      alert.type === "success"
                        ? "text-green-800"
                        : "text-red-800"
                    }`}
                  >
                    {alert.message}
                  </p>
                </div>
              </div>
            </div>
          )}

          <form
            onSubmit={formik.handleSubmit}
            className="space-y-8 divide-y divide-gray-900/10"
          >
            <div className="space-y-6">
              <div className="mb-6 border-b border-gray-900/10 pb-6">
                <h2 className="text-sm font-semibold text-gray-900">
                  Personal Information
                </h2>
                <p className="mt-1 text-xs text-gray-600">
                  Update your details to make sure we can reach you when needed.
                </p>
              </div>
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                    value={formik.values.firstName || ""}
                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-red-600 text-sm">
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>
                {/* Last Name */}
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                    value={formik.values.lastName || ""}
                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-red-600 text-sm">
                      {formik.errors.lastName}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email <span className="text-red-500">*</span>
                </label>
                <div className="mt-2 space-y-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                    value={formik.values.email || ""}
                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="pt-6 space-y-6">
              <div>
                <h2 className="text-sm font-semibold text-gray-900">
                  Change Password
                </h2>
                <p className="mt-1 text-xs text-gray-600">
                  Set a new password to keep your account safe. Make sure it’s
                  strong and secure.
                </p>
              </div>
              {/* Password */}
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    New Password <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                      value={formik.values.password || ""}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      {showPassword ? (
                        <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                      ) : (
                        <EyeIcon className="h-5 w-5 text-gray-500" />
                      )}
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-red-600 text-sm">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>

                {/* Confirm Password */}
                <div>
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Confirm Password <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                      value={formik.values.confirmPassword || ""}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      {showConfirmPassword ? (
                        <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                      ) : (
                        <EyeIcon className="h-5 w-5 text-gray-500" />
                      )}
                    </button>
                  </div>
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="text-red-600 text-sm">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Submit Button */}
              <div className="mt-6 flex flex-row-reverse items-center justify-start gap-x-6">
                <button
                  type="submit"
                  disabled={!formik.isValid || isSubmitting}
                  className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                    !formik.isValid || isSubmitting
                      ? "bg-gray-500 cursor-not-allowed"
                      : "bg-indigo-600 hover:bg-indigo-500"
                  }`}
                >
                  {isSubmitting ? (
                    <div className="flex items-center space-x-2">
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v4l-3 3 3 3v4a8 8 0 01-8-8z"
                        ></path>
                      </svg>
                      <span>Processing...</span>
                    </div>
                  ) : (
                    "Save changes"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default withAuth(Settings, [0, 1, 2]);
