import * as Yup from "yup";

import { Layout, ModalAlert } from "@components";
import { gql, useMutation, useQuery } from "@apollo/client";

import CurrencyInput from "react-currency-input-field";
import Filter from "./filter";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import formatNumber from "../../utils/formatNumber";
import moment from "moment";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import useQueryParams from "../../utils/query_params";
import { useState } from "react"; // Import useState hook

const ADMIN_ALLOWANCES_QUERY = gql`
  query (
    $endDate: String
    $page: Int!
    $pageSize: Int!
    $personnelId: String
    $program: String
    $client: String
    $startDate: String
    $status: String
  ) {
    adminAllowances(
      endDate: $endDate
      page: $page
      pageSize: $pageSize
      personnelId: $personnelId
      program: $program
      client: $client
      startDate: $startDate
      status: $status
    ) {
      pageNumber
      pageSize
      totalPages
      totalEntries
      entries {
        allowanceDate
        allowanceReference
        allowanceType {
          id
          referenceCode
          referenceDescription
          referenceType
        }
        cost
        expiryDate
        id
        insertedAt
        liquidatedCost
        program {
          id
          programName
          clientName
        }
        personnel {
          firstName
          lastName
        }
        receiveReceiptDate
        status
        updatedAt
      }
    }
  }
`;

const LIQUIDATED_ALLOWANCES_QUERY = gql`
  query (
    $startDate: String
    $endDate: String
  ) {
    downloadAllowancePayables(
      startDate: $startDate
      endDate: $endDate
    ) {
      allowances {
        allowanceDate
        allowanceReference
        allowanceType {
          id
          referenceCode
          referenceDescription
          referenceType
        }
        cost
        expiryDate
        id
        insertedAt
        liquidatedCost
        program {
          id
          programName
          clientName
        }
        personnel {
          firstName
          lastName
        }
        receiveReceiptDate
        status
        updatedAt
      }
    }
  }
`;

const EXPIRE_ALLOWANCE_MUTATION = gql`
  mutation ($id: String!) {
    expireAllowance(id: $id) {
      allowance {
        id
      }
    }
  }
`;

const LIQUIDATE_ALLOWANCE_MUTATION = gql`
  mutation (
    $id: String!
    $allowanceReference: String!
    $liquidatedCost: Float!
    $receiveReceiptDate: String!
  ) {
    liquidateAllowance(
      id: $id
      allowanceReference: $allowanceReference
      liquidatedCost: $liquidatedCost
      receiveReceiptDate: $receiveReceiptDate
    ) {
      allowance {
        id
      }
    }
  }
`;

// Update validation schema
const validationLiquidateSchema = Yup.object({
  allowanceReference: Yup.string().required("Allowance reference is required"),
  receiveReceiptDate: Yup.date().required("Receive receipt date is required"),
  liquidatedCost: Yup.number()
    .required("Liquidated cost is required")
    .positive("Liquidated cost must be a positive number"),
});

// Update validation schema
const validationDownloadSchema = Yup.object({
  liquidationDateStart: Yup.string().required("Start Date is required"),
  liquidationDateEnd: Yup.date().required("End Date is required"),
});

export default function List({ triggerDownload }) {
  const location = useLocation();
  const { getQueryParam, setQueryParam, setMultipleQueryParams } =
    useQueryParams();
  const [page, setPage] = useState(
    getQueryParam("page") ? parseInt(getQueryParam("page")) : 1
  );
  const [pageSize, setPageSize] = useState(
    getQueryParam("pageSize") ? parseInt(getQueryParam("pageSize")) : 10
  );
  const [dataEntries, setDataEntries] = useState({});
  const [showExpireModal, setShowExpireModal] = useState(false);
  const [showLiquidateModal, setShowLiquidateModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [mutationLoading, setmutationLoading] = useState(false);
  const [selectedAllowance, setSelectedAllowance] = useState(null);

  const getQueryParamOrNull = (params) => {
    const value = getQueryParam(params);
    return value === "all" ? null : value;
  };

  const { loading, error, data, refetch } = useQuery(ADMIN_ALLOWANCES_QUERY, {
    variables: {
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
      startDate: getQueryParamOrNull("startDate"),
      endDate: getQueryParamOrNull("endDate"),
      client: getQueryParam("clientId") ? getQueryParam("clientId") : "all",
      program: getQueryParam("programId") ? getQueryParam("programId") : "all",
      personnelId: getQueryParam("personnelId")
        ? getQueryParam("personnelId")
        : "all",
      status: getQueryParam("status") ? getQueryParam("status") : "all",
    },
  });

  const liquidatedData = useQuery(LIQUIDATED_ALLOWANCES_QUERY);

  const [expire_allowance, { loading: mutationExpireLoading }] = useMutation(
    EXPIRE_ALLOWANCE_MUTATION,
    {
      onCompleted: (data) => {
        if (data && data.expireAllowance) {
          // Refetch the list and close the modal on success
          refetch().then(() => {
            setShowExpireModal(false); // Close the modal
          });
        } else {
          console.error("Error expiring the allowance");
        }
      },
      onError: (error) => {
        console.error("Error expiring the allowance:", error);
      },
    }
  );

  const [liquidateAllowance, { loading: mutationLiquidateLoading }] =
    useMutation(LIQUIDATE_ALLOWANCE_MUTATION, {
      onCompleted: () => {
        refetch();
        setShowLiquidateModal(false);
      },
      onError: (error) => {
        console.error("Error liquidating the allowance:", error);
      },
    });

  useEffect(() => {
    if (getQueryParam("page")) {
      setPage(parseInt(getQueryParam("page")));
    }
    refetch({
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
      startDate: getQueryParamOrNull("startDate"),
      endDate: getQueryParamOrNull("endDate"),
      client: getQueryParam("clientId") ? getQueryParam("clientId") : "all",
      program: getQueryParam("programId") ? getQueryParam("programId") : "all",
      personnelId: getQueryParam("personnelId")
        ? getQueryParam("personnelId")
        : "all",
      status: getQueryParam("status") ? getQueryParam("status") : "all",
    });
  }, [location.search]);

  const formikLiquidate = useFormik({
    initialValues: {
      allowanceReference: "",
      receiveReceiptDate: "",
      liquidatedCost: "",
    },
    validationSchema: validationLiquidateSchema,
    onSubmit: (values) => {
      if (showLiquidateModal && selectedAllowance) {
        liquidateAllowance({
          variables: {
            id: selectedAllowance.id,
            allowanceReference: values.allowanceReference, // Replace with actual reference
            liquidatedCost: parseFloat(values.liquidatedCost), // Convert amount to Float
            receiveReceiptDate: values.receiveReceiptDate,
          },
        });
      }
    },
  });

  const currentDate = new Date().toISOString().split("T")[0];

  const formikDownload = useFormik({
    initialValues: {
      liquidationDateStart: null,
      liquidationDateEnd: currentDate,
    },
    validationSchema: validationDownloadSchema,
    onSubmit: (values) => {
      console.log("Start: " + values.liquidationDateStart);
      console.log("End  : " + values.liquidationDateStart);
    },
  });

  // Update the selected allowance when the "Liquidate" button is clicked
  const handleLiquidateClick = (allowance) => {
    // Check if allowance.Personnel is populated
    if (!allowance.personnel) {
      alert("Error: Personnel information is missing."); // Display an error message
      return; // Exit the function
    }

    setSelectedAllowance(allowance); // Store the clicked allowance data
    setShowLiquidateModal(true); // Show the modal

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
    const day = String(date.getDate()).padStart(2, "0"); // getDate() returns the day of the month

    const default_day = `${year}-${month}-${day}`;

    // Set default form values using Formik's setValues
    formikLiquidate.setValues({
      allowanceReference: allowance.allowanceReference || "",
      receiveReceiptDate: default_day,
      liquidatedCost: allowance.cost || "",
    });
  };

  const handlePageClick = (e) => {
    const newPage = e.selected + 1;
    setPage(newPage);
    setQueryParam("page", parseInt(newPage));
  };

  const isExpire = (date) => {
    const dateString = date;

    if (dateString === null || dateString === undefined) {
      console.error("Invalid Date");
      return true;
    } else {
      // Parse the date string into a Date object
      const givenDate = new Date(dateString);
      // Add 45 days to the given date

      givenDate.setDate(givenDate.getDate() + 45);

      // Get today's date (only the date part, ignoring the time)
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set the time to midnight for an accurate comparison

      // Check if today's date is equal to or after the date after adding 45 days
      if (today.getTime() > givenDate.getTime()) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isReadyForLiquidation = (date) => {
    const dateString = date;

    if (dateString === null || dateString === undefined) {
      console.error("Invalid Date");
      return true;
    } else {
      // Parse the date string into a Date object
      const givenDate = new Date(dateString);

      // Get today's date (only the date part, ignoring the time)
      const today = new Date();
      givenDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0); // Set the time to midnight for an accurate comparison

      if (givenDate.getTime() <= today.getTime()) {
        return true;
      } else {
        return false;
      }
    }
  };

  // useEffect(() => {
  //   setDataEntries(data);
  // }, [data]);

  useEffect(() => {
    if (triggerDownload) {
      setShowDownloadModal(true);
    }
  }, [triggerDownload]);

  const handleDownloadCSV = async () => {
    try {

      if (formikDownload.values.liquidationDateStart === null || formikDownload.values.liquidationDateEnd === null ) {
        alert("Please enter Start and End date.")
        return;
      }

      // Check if data is available, then proceed with download
      if (liquidatedData 
          && liquidatedData.data.downloadAllowancePayables.allowances 
          &&  liquidatedData.data.downloadAllowancePayables.allowances.length !== 0
      ) {
        downloadCSV(liquidatedData.data.downloadAllowancePayables.allowances);
        formikDownload.resetForm();
      } else {
        alert("No data found.")
      }
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const downloadCSV = (csvdata) => {

    // Helper function to sanitize fields (removes commas)
    const sanitizeField = (value) => {
      if (typeof value === "string") {
        return value.replace(/,/g, ""); // Remove commas from strings
      }
      return value;
    };
    const sanitizeName = (value) => {
      if (typeof value === "string") {
        return value.replace(/,/g, ""); // Remove commas from strings
      }
      return value || "Unknown"; // Default to 'Unknown' for missing values;
    };

    // Helper function to generate and download CSV
    const createAndDownloadCSV = (filename, data) => {
      const csvRows = [
        Object.keys(data[0]).join(","), // Headers
        ...data.map((row) =>
          Object.values(row)
            .map((value) => sanitizeField(value))
            .join(",")
        ), // Data rows
      ].join("\n");

      const blob = new Blob([csvRows], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
    };

    // Prepare data for PayablesDetailed (Ordered by lastName, firstName, liquidationDate)
    const fileAllowancesDetailed = csvdata
      .map((entry) => ({
        lastName: sanitizeName(entry.personnel?.lastName),
        firstName: sanitizeName(entry.personnel?.firstName),
        liquidationDate: entry.receiveReceiptDate,
        clientName: entry.program.clientName,
        programName: entry.program.programName,
        role: entry.allowanceType.referenceDescription,
        allowance: entry.liquidatedCost,
      }))
      .sort(
        (a, b) =>
          a.lastName.localeCompare(b.lastName) ||
          a.firstName.localeCompare(b.firstName) ||
          new Date(a.liquidationDate) - new Date(b.liquidationDate)
      );

    // Prepare data for PayablesGrouped (Aggregated totals, ordered by lastName, firstName)
    const personnelAggregates = {};
    csvdata.forEach((entry) => {
      const lastName = sanitizeName(entry.personnel?.lastName);
      const firstName = sanitizeName(entry.personnel?.firstName);
      const key = `${lastName}|${firstName}`;

      if (!personnelAggregates[key]) {
        personnelAggregates[key] = {
          lastName: sanitizeName(entry.personnel?.lastName),
          firstName: sanitizeName(entry.personnel?.firstName),
          totalAllowance: 0,
        };
      }
      personnelAggregates[key].totalAllowance += entry.liquidatedCost;
    });

    const fileAllowancesGrouped = Object.values(personnelAggregates)
      .map(
        ({
          lastName,
          firstName,
          totalAllowance,
        }) => ({
          lastName,
          firstName,
          totalAllowance,
        })
      )
      .sort(
        (a, b) =>
          a.lastName.localeCompare(b.lastName) ||
          a.firstName.localeCompare(b.firstName)
      );

    // Download both files
    createAndDownloadCSV("fileAllowancesDetailed.csv", fileAllowancesDetailed);

    setTimeout(() => {
      createAndDownloadCSV("fileAllowancesGrouped.csv", fileAllowancesGrouped);
    }, 2500); // Delay of 2.5 seconds

    setShowDownloadModal(false); // Reset after handling
  };

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.adminAllowances.pageNumber
    ? (data.adminAllowances.pageNumber - 1) * data.adminAllowances.pageSize + 1
    : 0;

  return (
    <>
      <div className="mt-8 flow-root overflow-hidden">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <table className="w-full text-left">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="relative isolate process-th min-w-28 w-28"
                >
                  Date
                  <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                  <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                </th>
                <th scope="col" className="process-th w-fit">
                  Program
                </th>
                <th scope="col" className="process-th min-w-36 w-36">
                  Personnel
                </th>
                <th scope="col" className="process-th min-w-32">
                  Type
                </th>
                <th scope="col" className="process-th w-fit">
                  Amount
                </th>
                {/* <th
                    scope="col"
                    className="process-th"
                  >
                    Liquidated Cost
                  </th> */}
                <th
                  scope="col"
                  className="process-th min-w-64 w-64 !text-left !pl-3"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.adminAllowances?.entries.map((allowances) => (
                <tr
                  id={allowances.id}
                  key={allowances.id}
                  className="cursor-pointer hover:bg-gray-50"
                >
                  <td className="relative process-results">
                    {moment(allowances.allowanceDate).format("YYYY-MMM-DD")}
                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                  </td>
                  <td className="process-results">
                    {allowances.program.programName}
                    <span className="text-xs">
                      <br/>{allowances.program.clientName}
                    </span>
                  </td>
                  <td className="process-results">
                    {allowances?.personnel?.firstName}{" "}
                    {allowances?.personnel?.lastName}
                  </td>
                  <td className="process-results">
                    {allowances?.allowanceType?.referenceDescription}
                  </td>
                  <td className="process-results text-right">
                    {allowances.status == "liquidated" ? (
                      <>
                        Used {formatNumber(allowances.liquidatedCost)}
                        <span className="text-xs">
                          <br />out of{" "}{formatNumber(allowances.cost)}
                        </span>
                      </>
                    ) : ( 
                    formatNumber(allowances.cost)
                    )}
                  </td>
                  {/* <td className="process-results">
                      {formatNumber(allowances.liquidatedCost)}
                    </td> */}

                  <td className="process-results">
                    {allowances.status == "active" ? (
                      <div className="flex flex-row space-x-3 !pl-3">
                        {isReadyForLiquidation(allowances.allowanceDate) ? (
                          <>
                            <button
                              onClick={() => handleLiquidateClick(allowances)}
                              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Liquidate
                            </button>
                            {allowances.allowanceType.referenceCode ===
                              "ef" && (
                              <button
                                onClick={() => {
                                  setSelectedId(allowances.id);
                                  setSelectedType(
                                    allowances.allowanceType.referenceCode
                                  );
                                  setShowExpireModal(true);
                                }}
                                className="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                              >
                                Not Used
                              </button>
                            )}
                          </>
                        ) : (
                          <div>Not Ready for Liquidation</div>
                        )}
                        {allowances.allowanceType.referenceCode !== "ef" &&
                          isExpire(allowances.allowanceDate) && (
                            <button
                              onClick={() => {
                                setSelectedId(allowances.id);
                                setSelectedType(
                                  allowances.allowanceType.referenceCode
                                );
                                setShowExpireModal(true);
                              }}
                              className="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            >
                              Expire
                            </button>
                          )}
                      </div>
                    ) : (
                      <div className="pl-3">
                        {allowances.status === "expired" &&
                          (allowances.allowanceType.referenceCode === "ef" ? (
                            <>Not Used</>
                          ) : (
                            <>
                              Expired 
                              <span className="text-xs">
                                <br />{moment(allowances.expiryDate).format("YYYY-MMM-DD")}
                              </span>
                            </>
                          ))}
                        {allowances.status == "liquidated" && (
                          <>
                            Liquidated
                            <span className="text-xs">
                              <br />{moment(allowances.receiveReceiptDate).format("YYYY-MMM-DD")}
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data && (
            <div className="py-4 flex flex-row items-center justify-between mt-6">
              <div className="flex flex-wrap items-center justify-between text-sm text-gray-700">
                <div className="flex items-center space-x-2">
                  <span>
                    Showing{" "}
                    <span className="font-medium">{startOfResults}</span> to
                  </span>
                  <label htmlFor="pageSize" className="sr-only">
                    Results per page:
                  </label>
                  <select
                    id="pageSize"
                    name="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(parseInt(e.target.value, 10));
                      setQueryParam("pageSize", parseInt(e.target.value, 10));
                    }}
                    className="rounded-md bg-white py-1.5 px-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm"
                  >
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={1000}>1000</option>
                  </select>
                  <span>
                    of{" "}
                    <span className="font-medium">
                      {data.adminAllowances.totalEntries}
                    </span>{" "}
                    results
                  </span>
                </div>
              </div>

              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <span>
                    <span className="sr-only">Next</span>
                    <svg
                      className="h-5 w-5 ml-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                }
                previousLabel={
                  <span>
                    <svg
                      className="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Previous</span>
                  </span>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={data.adminAllowances.totalPages} // Total number of pages
                forcePage={page - 1} // Sync with the current page (zero-indexed)
                breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              />
            </div>
          )}
        </div>
      </div>

      {showExpireModal && (
        <ModalAlert open={showExpireModal} setOpen={setShowExpireModal}>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg
                className="h-6 w-6 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3
                className="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                {selectedType === "ef"
                  ? `Confirm Allowance as Not Used`
                  : `Confirm Allowance as Expired`}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {selectedType === "ef"
                    ? `Are you sure you want to mark this as not used?`
                    : `Are you sure you want to expire this allowance?`}
                </p>
              </div>
            </div>
          </div>

          {/* Modal actions */}
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              onClick={() => {
                if (selectedId) {
                  expire_allowance({
                    variables: {
                      id: selectedId,
                    },
                  });
                }
              }}
              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            >
              {mutationExpireLoading ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3 ..."
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                "Yes"
              )}
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              onClick={() => setShowExpireModal(false)}
            >
              No
            </button>
          </div>
        </ModalAlert>
      )}

      {/* Liquidate Modal */}
      {showLiquidateModal && (
        <ModalAlert open={showLiquidateModal} setOpen={setShowLiquidateModal}>
          <form onSubmit={formikLiquidate.handleSubmit}>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Liquidate Allowance
              </h3>
              <p className="mt-2 text-xs text-gray-500">
                Please enter the details for liquidating the allowance.
              </p>

              <div>
                <label
                  htmlFor="receiveReceiptDate"
                  className="label-on-input"
                >
                  Date
                </label>
                <input
                  id="receiveReceiptDate"
                  name="receiveReceiptDate"
                  type="date"
                  onChange={formikLiquidate.handleChange}
                  onBlur={formikLiquidate.handleBlur}
                  value={formikLiquidate.values.receiveReceiptDate}
                  max={currentDate}
                  className="block w-full rounded-md border-0 py-1.5 mt-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  tabIndex="5"
                />
                {formikLiquidate.touched.receiveReceiptDate &&
                  formikLiquidate.errors.receiveReceiptDate && (
                    <div className="text-red-600 text-sm">
                      {formikLiquidate.errors.receiveReceiptDate}
                    </div>
                  )}
              </div>

              <div>
                <label
                  htmlFor="liquidatedCost"
                  className="label-on-input"
                >
                  Amount
                </label>
                <CurrencyInput
                  id="liquidatedCost"
                  name="liquidatedCost"
                  value={formikLiquidate.values.liquidatedCost}
                  decimalsLimit={2}
                  onValueChange={(value, name, values) =>
                    formikLiquidate.setFieldValue("liquidatedCost", value)
                  }
                  className="block w-full rounded-md border-0 py-1.5 mt-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  tabIndex="1"
                />
                {formikLiquidate.touched.liquidatedCost &&
                  formikLiquidate.errors.liquidatedCost && (
                    <div className="text-red-600 text-sm">
                      {formikLiquidate.errors.liquidatedCost}
                    </div>
                  )}
              </div>

              <div>
                <label
                  htmlFor="allowanceReference"
                  className="label-on-input"
                >
                  Reference
                </label>

                {/* <input
                  id="allowanceReference"
                  name="allowanceReference"
                  type="text"
                  onChange={formikLiquidate.handleChange}
                  onBlur={formikLiquidate.handleBlur}
                  value={formikLiquidate.values.allowanceReference}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  tabIndex="2"
                  maxLength={255}
                />
                {formikLiquidate.touched.allowanceReference &&
                  formikLiquidate.errors.allowanceReference && (
                    <div className="text-red-600 text-sm">
                      {formikLiquidate.errors.allowanceReference}
                    </div>
                  )} */}
              </div>

              <div className="mt-2 space-y-2">
                <textarea
                  rows={3}
                  id="allowanceReference"
                  name="allowanceReference"
                  type="text"
                  onChange={formikLiquidate.handleChange}
                  onBlur={formikLiquidate.handleBlur}
                  value={formikLiquidate.values.allowanceReference}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  tabIndex="2"
                />
                {formikLiquidate.touched.allowanceReference &&
                formikLiquidate.errors.allowanceReference ? (
                  <div className="text-red-600 text-sm">
                    {formikLiquidate.errors.allowanceReference}
                  </div>
                ) : null}
              </div>
            </div>
            {/* Modal actions */}
            <div className="mt-5 pt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                tabIndex="3"
              >
                {mutationLiquidateLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 ..."
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Confirm"
                )}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                tabIndex="4"
                onClick={() => setShowLiquidateModal(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </ModalAlert>
      )}

      {/* Download Modal */}
      {showDownloadModal && (
        <ModalAlert open={showDownloadModal} setOpen={setShowDownloadModal}>
          <form onSubmit={formikDownload.handleSubmit}>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Download Liquidated Allowances
              </h3>
              <p className="mt-2 text-xs text-gray-500">
                Please enter the start and end dates you want to download.
              </p>

              <div>
                <label
                  htmlFor="liquidationDateStart"
                  className="label-on-input"
                >
                  Start Date
                </label>
                <input
                  autoFocus
                  id="liquidationDateStart"
                  name="liquidationDateStart"
                  type="date"
                  //onChange={formikDownload.handleChange}
                  onChange={(event) => {
                    formikDownload.handleChange(event); // Call formik's onChange
                    liquidatedData.refetch({
                      startDate: event.target.value,
                      endDate: formikDownload.values.liquidationDateEnd,
                    });
                  }}
                  onBlur={formikDownload.handleBlur}
                  //value={formikDownload.values.receiveReceiptDate}
                  max={currentDate}
                  className="block w-full rounded-md border-0 py-1.5 mt-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  tabIndex="5"
                />
                {formikDownload.touched.liquidationDateStart &&
                  formikDownload.errors.liquidationDateStart && (
                    <div className="text-red-600 text-sm">
                      {formikLiquidate.errors.liquidationDateStart}
                    </div>
                  )}
              </div>

              <div>
                <label
                  htmlFor="receiveReceiptDate"
                  className="label-on-input"
                >
                  End Date
                </label>
                <input
                  id="liquidationDateEnd"
                  name="liquidationDateEnd"
                  type="date"
//                  onChange={formikDownload.handleChange}
                  onChange={(event) => {
                    formikDownload.handleChange(event); // Call formik's onChange
                    liquidatedData.refetch({
                      startDate: formikDownload.values.liquidationDateStart,
                      endDate: event.target.value, //formikDownload.values.liquidationDateEnd,
                    });
                  }}
                  onBlur={formikDownload.handleBlur}
                  value={currentDate}
                  max={currentDate}
                  className="block w-full rounded-md border-0 py-1.5 mt-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  tabIndex="5"
                />
                {formikDownload.touched.liquidationDateEnd &&
                  formikDownload.errors.liquidationDateEnd && (
                    <div className="text-red-600 text-sm">
                      {formikDownload.errors.liquidationDateEnd}
                    </div>
                  )}
              </div>

              
            </div>
            {/* Modal actions */}
            <div className="mt-5 pt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                //type="submit"
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                tabIndex="3"
                onClick={handleDownloadCSV}
              >
                Download
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                tabIndex="4"
                onClick={() => setShowDownloadModal(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </ModalAlert>
      )}


    </>
  );
}
