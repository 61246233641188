import * as Yup from "yup";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react"; // Import useState hook

import { Layout, ModalAlert } from "@components";
import { useFormik } from "formik";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useLocation } from "react-router-dom";
import useQueryParams from "../../utils/query_params";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

const validationSchema = Yup.object({
  baseline_date: Yup.string().required("Required"),
});

const ALL_PROGRAMS_QUERY = gql`
  query (
    $client: String
    $program: String
    $status: String
    $page: Int!
    $pageSize: Int!
  ) {
    programs(
      client: $client
      program: $program
      status: $status
      page: $page
      pageSize: $pageSize
    ) {
      pageNumber
      pageSize
      totalPages
      totalEntries
      entries {
        id
        clientName
        clientDepartment
        clientContactDetails
        programName
        programStatus
        programVenue
        programPax
        programTypeIndoorModular
        programTypeTeambuilding
        programTypeConsultation
        programTypeIndoorConvention
        programEmtCost
        programToolName
        programToolCost
        programToolPrice
        programToolQuantity
        programOverhead
        programContractPrice
        createdBy {
          id
          firstName
          lastName
        }
        updatedBy {
          id
          firstName
          lastName
        }
        insertedAt
        updatedAt
      }
    }
  }
`;

// TODO: SAINT
const DUPLICATE_PROGRAM_MUTATION = gql`
  mutation ($originalProgramId: String!, $baselineDate: String!) {
    duplicateProgram(
      originalProgramId: $originalProgramId
      baselineDate: $baselineDate
    ) {
      status
      programId
    }
  }
`;

export default function List() {
  const location = useLocation();
  const { getQueryParam, setQueryParam } = useQueryParams();
  const [page, setPage] = useState(
    getQueryParam("page") ? parseInt(getQueryParam("page")) : 1
  );
  const [pageSize, setPageSize] = useState(
    getQueryParam("pageSize") ? parseInt(getQueryParam("pageSize")) : 10
  );
  const [result, setResult] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");

  const { loading, error, data, refetch } = useQuery(ALL_PROGRAMS_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
      client: getQueryParam("client") ? getQueryParam("client") : "all",
      program: getQueryParam("program") ? getQueryParam("program") : "all",
      status: getQueryParam("status") ? getQueryParam("status") : "all",
    },
  });

  useEffect(() => {
    if (data && data.programs && data.programs.entries) {
      setResult(data.programs.entries);
    } else {
      setResult([]);
    }
  }, [data?.programs]);

  useEffect(() => {
    if (getQueryParam("page")) {
      setPage(parseInt(getQueryParam("page")));
    }
    refetch({
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
      client: getQueryParam("client") ? getQueryParam("client") : "all",
      program: getQueryParam("program") ? getQueryParam("program") : "all",
      status: getQueryParam("status") ? getQueryParam("status") : "all",
    });
  }, [location.search]);

  // TODO: SAINT
  const [duplicate_program, { loading: mutationLoading }] = useMutation(
    DUPLICATE_PROGRAM_MUTATION,
    {
      onCompleted: (data) => {
        if (data && data.duplicateProgram) {
          window.location.replace(
            `/programs/${data.duplicateProgram.programId}`
          );
          setShowModal(false);
        } else {
          console.error("Error duplicating program");
        }
      },
      onError: (error) => {
        console.error("Error duplicating program:", error);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      baseline_date: moment().format("YYYY-MM-DD"), // Set current date as default
    },
    validationSchema,
    onSubmit: (values) => {
      duplicate_program({
        variables: {
          originalProgramId: selectedId,
          duplicatedByUserId: "TODO: SAINT", // TODO: SAINT. How do we get User ID?
          baselineDate: values.baseline_date,
        },
      });
      setShowModal(false);
    },
  });

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    setQueryParam("page", e.selected + 1);
    // refetch({ page: e.selected + 1, pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.programs.pageNumber
    ? (data.programs.pageNumber - 1) * data.programs.pageSize + 1
    : 0;

  const statusClasses = {
    active: "text-green-800 bg-green-200",
    draft: "text-gray-800 bg-gray-200",
    cancelled: "text-white bg-red-500",
    completed: "text-white bg-green-700",
  };

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <>
      <div className="mt-8 flow-root overflow-hidden">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="overflow-auto sm:overflow-hidden ">
            <table className="w-full text-left">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="relative isolate process-th !text-left w-fit"
                  >
                    Program
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                  </th>
                  {/* <th
                      scope="col"
                      className="process-th !text-left"
                    >
                      Program Name
                    </th> */}
                  <th scope="col" className="process-th !text-left w-80">
                    Department
                  </th>
                  <th scope="col" className="process-th !text-left w-40">
                    Created
                  </th>
                  <th scope="col" className="process-th !text-left w-40 hidden">
                    Updated
                  </th>
                  {/* <th
                      scope="col"
                      className="process-th"
                    >
                      Created By
                    </th> */}
                  <th scope="col" className="process-th w-24">
                    Status
                  </th>
                  <th scope="col" className="process-th w-20">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {result.map((program) => (
                  <tr
                    id={program.id}
                    key={program.id}
                    className="cursor-pointer hover:bg-gray-50"
                  >
                    <td
                      onClick={() =>
                        window.location.replace(`/programs/${program.id}`)
                      }
                      className="relative process-results"
                    >
                      {program.programName}
                      <br />
                      <span className="text-xs">{program.clientName}</span>
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                    <td
                      onClick={() =>
                        window.location.replace(`/programs/${program.id}`)
                      }
                      className="process-results"
                    >
                      {program.clientDepartment}
                    </td>
                    <td
                      onClick={() =>
                        window.location.replace(`/programs/${program.id}`)
                      }
                      className="process-results"
                    >
                      {program.createdBy.firstName} {program.createdBy.lastName}
                      <br />
                      <span className="text-xs">
                        {moment(program.insertedAt).format(
                          "YYYY-MMM-DD HH:MM:ss"
                        )}
                      </span>
                    </td>
                    <td
                      onClick={() =>
                        window.location.replace(`/programs/${program.id}`)
                      }
                      className="process-results hidden"
                    >
                      {program?.updatedBy
                        ? `${program.updatedBy?.firstName || ""} ${
                            program.updatedBy?.lastName || ""
                          }`
                        : `${program.createdBy?.firstName || ""} ${
                            program.createdBy?.lastName || ""
                          }`}
                      <br />
                      <span className="text-xs">
                        {program?.updatedBy
                          ? moment(program.updatedAt).format(
                              "YYYY-MMM-DD HH:MM:ss"
                            )
                          : moment(program.insertedAt).format(
                              "YYYY-MMM-DD HH:MM:ss"
                            )}
                      </span>
                    </td>
                    {/* <td
                        onClick={() =>
                          window.location.replace(`/programs/${program.id}`)
                        }
                        className="process-results"
                      >
                        {program.createdBy.firstName} {program.createdBy.lastName}
                      </td> */}
                    <td
                      onClick={() =>
                        window.location.replace(`/programs/${program.id}`)
                      }
                      className="process-results"
                    >
                      <span
                        className={`p-1.5 text-xs uppercase font-medium tracking-wider rounded-lg ${
                          statusClasses[program.programStatus]
                        }`}
                      >
                        {program.programStatus}
                      </span>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setSelectedId(program.id);
                          setSelectedProgram(program.programName);
                          setShowModal(true);
                        }}
                        className="rounded-md bg-blue-600 p-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        <DocumentDuplicateIcon
                          className="h-3 w-3"
                          aria-hidden="true"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data && (
            <div className="py-4 flex flex-row items-center justify-between mt-6">
              <div className="flex flex-wrap items-center justify-between text-sm text-gray-700">
                <div className="flex items-center space-x-2">
                  <span>
                    Showing{" "}
                    <span className="font-medium">{startOfResults}</span> to
                  </span>
                  <label htmlFor="pageSize" className="sr-only">
                    Results per page:
                  </label>
                  <select
                    id="pageSize"
                    name="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(parseInt(e.target.value, 10));
                      setQueryParam("pageSize", parseInt(e.target.value, 10));
                      // refetch({
                      //   page,
                      //   pageSize: parseInt(e.target.value, 10),
                      // });
                    }}
                    className="rounded-md bg-white py-1.5 px-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm"
                  >
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={1000}>1000</option>
                  </select>
                  <span>
                    of{" "}
                    <span className="font-medium">
                      {data.programs.totalEntries}
                    </span>{" "}
                    results
                  </span>
                </div>
              </div>

              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <span>
                    <span className="sr-only">Next</span>
                    <svg
                      className="h-5 w-5 ml-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                }
                previousLabel={
                  <span>
                    <svg
                      className="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Previous</span>
                  </span>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={data.programs.totalPages} // Total number of pages
                forcePage={page - 1} // Sync with the current page (zero-indexed)
                breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              />
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <>
          <ModalAlert open={showModal} setOpen={setShowModal}>
            <form onSubmit={formik.handleSubmit}>
              {/* Modal content */}
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Duplicate Program
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Please enter the baseline date for the duplicate of <br />
                  <strong>{selectedProgram}</strong>.
                </p>
                <div className="mt-3">
                  <label
                    htmlFor="baseline_date"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Baseline Date
                  </label>

                  <div className="mt-2 space-y-2">
                    <input
                      id="baseline_date"
                      name="baseline_date"
                      type="date"
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.baseline_date}
                      tabIndex="4"
                    />
                    {formik.touched.baseline_date &&
                    formik.errors.baseline_date ? (
                      <div className="text-red-600 text-sm">
                        {formik.errors.baseline_date}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* Modal actions */}
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                  tabIndex="2"
                >
                  {mutationLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 mr-3 ..."
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Confirm"
                  )}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => setShowModal(false)}
                  tabIndex="3"
                >
                  Cancel
                </button>
              </div>
            </form>
          </ModalAlert>
        </>
      )}
    </>
  );
}
