import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import { gql, useQuery } from "@apollo/client";

const GET_USER = gql`
  query {
    getUserInfo {
      status
      user {
        id
        email
	accessLevel
      }
    }
  }
`;

const withAuth = (WrappedComponent, accessLevel) => {
  return (props) => {
    const { data, loading, error } = useQuery(GET_USER);

    if (loading) return <Loader/>;
    if (error) return <Navigate to="/home" replace />;

    const user = data.getUserInfo.user;
    
    if (accessLevel && !accessLevel.includes(parseInt(user.accessLevel))) {
      return <Navigate to="/home" replace />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;