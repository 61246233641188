import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";

import { CalendarIcon } from "@heroicons/react/24/solid";
import { Layout } from "@components";
import Loader from "../../../components/Loader";

const FACILITATOR_PERFORMANCE_REPORT_QUERY = gql`
  query ($date: String) {
    facilitatorPerformanceReport(date: $date) {
      status
      facilitatorPerformanceResult {
        id
        name
        percentage
        roundedPercentage
        totalHours
      }
    }
  }
`;

export default function FacilitatorPerformanceReport() {
  const [selectedDate, setSelectedDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );

  const { data, loading, error, refetch } = useQuery(
    FACILITATOR_PERFORMANCE_REPORT_QUERY,
    {
      variables: { date: selectedDate },
    }
  );

  if (error) return <p className="text-red-500">Error: {error.message}</p>;

  const rows =
    data?.facilitatorPerformanceReport?.facilitatorPerformanceResult || [];
  const totalHours = rows.reduce(
    (acc, item) => acc + (parseFloat(item.totalHours) || 0),
    0
  );

  const formatNumber = (num) =>
    new Intl.NumberFormat("en-PH", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Facilitator Performance Report
              </h1>
              <p className="mt-2 text-xs text-gray-700">
                A list of All the Facilitator Performance Report
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-12 md:col-span-2 items-start mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="sm:col-span-3">
            <label htmlFor="start_date" className="label-on-input">
              Date
            </label>

            <div className="mt-2 space-y-2">
              <input
                autoFocus
                type="date"
                id="filter-date"
                onChange={(e) => {
                  if (moment(e.target.value, "YYYY-MM-DD", true).isValid()) {
                    setSelectedDate(e.target.value);
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="w-full text-left">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="relative isolate process-th min-w-24"
                  >
                    Name
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                  </th>
                  <th className="process-th">
                    <p class="text-right">Total Hours</p>
                  </th>
                  <th className="process-th">
                    <p class="text-right">Percentage</p>
                  </th>
                  {/* <th className="process-th">
                    <p class="text-right">Rounded %</p>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {rows.map((item) => (
                  <tr key={item.id} className="cursor-pointer hover:bg-gray-50">
                    <td className="process-results">
                      <p className="text-sm font-semibold text-gray-800 py-2">
                        {item.name}
                      </p>
                    </td>
                    <td className="process-results text-right">
                      <p className="text-sm font-normal text-gray-800 py-2">
                        {formatNumber(item.totalHours)}
                      </p>
                    </td>
                    <td className="process-results text-right">
                      <p className="text-sm font-normal text-gray-800 py-2">
                        {formatNumber(item.percentage)}%
                      </p>
                    </td>
                    {/* <td className="process-results text-right">
                      <p className="text-sm font-normal text-gray-800 py-2">
                        {item.roundedPercentage}%
                      </p>
                    </td> */}
                  </tr>
                ))}
                {rows.length === 0 && (
                  <tr>
                    <td colSpan="4" className="text-center py-4 text-gray-500">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}
