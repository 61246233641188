import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import Filter from "./filter";
import { Layout } from "@components";
import withAuth from "../../middlewares/withAuth";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend
);

const GET_ROSTER_RATE_SUMMARY_QUERY = gql`
  query ($personnelId: String, $references: [String]) {
    getRosterRateSummary(personnelId: $personnelId, references: $references) {
      status
      rosterRateSummaryResult {
        id
        roleId
        roleDescription
        amount
        date
      }
    }
  }
`;

function Report() {
  const [queryFilters, setQueryFilters] = useState({ page: 1 });
  // Fetch data from API
  const { data, loading, refetch, error } = useQuery(
    GET_ROSTER_RATE_SUMMARY_QUERY
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  /*   
  Data for Original Charts
  // Process data for charts
  const chartLabels = data.getRosterRateSummary.rosterRateSummaryResult.map(
    (item) => item.date
  );
  const chartData = data.getRosterRateSummary.rosterRateSummaryResult.map(
    (item) => item.amount
  ); */

  // CODE for Stacked Bar Chart
  // Extract unique dates and role IDs
  const uniqueDates = Array.from(
    new Set(
      data.getRosterRateSummary.rosterRateSummaryResult.map((item) => item.date)
    )
  );
  const uniqueRoles = Array.from(
    new Set(
      data.getRosterRateSummary.rosterRateSummaryResult.map(
        (item) => item.roleDescription
      )
    )
  );

  // Create datasets
  const datasets = uniqueRoles.map((roleDescription, index) => {
    const dataValues = uniqueDates.map((date) => {
      const entry = data.getRosterRateSummary.rosterRateSummaryResult.find(
        (item) => item.date === date && item.roleDescription === roleDescription
      );
      return entry ? entry.amount : 0; // Default to 0 if no entry exists
    });

    return {
      label: `${roleDescription}`, // Customize role label as needed
      data: dataValues,
      backgroundColor: `rgba(${(index + 1) * 50}, 100, ${
        (index + 1) * 70
      }, 0.5)`, // Dynamic color
      borderColor: `rgba(${(index + 1) * 50}, 100, ${(index + 1) * 70}, 1)`,
      borderWidth: 1,
    };
  });

  // Stacked Bar chart data and options
  const StackedBarData = {
    labels: uniqueDates,
    datasets,
  };

  const StackedBarOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true, beginAtZero: true },
    },
  };

  /* 
  Original Code for Simple Bar Chart
  // Bar chart data
  const barData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Roster Rate Summary",
        data: chartData,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }; */

  // CODE for Doughtnut Bar Chart

  // Group data by roleId
  const roleDataMap = data.getRosterRateSummary.rosterRateSummaryResult.reduce(
    (acc, item) => {
      acc[item.roleDescription] =
        (acc[item.roleDescription] || 0) + item.amount;
      return acc;
    },
    {}
  );

  // Extract labels and data for the chart
  const doughnutLabels = Object.keys(roleDataMap);
  const doughnutDataValues = Object.values(roleDataMap);

  // Doughnut chart data
  const doughnutData = {
    labels: doughnutLabels.map((role) => `${role}`), // Customize labels if needed
    datasets: [
      {
        data: doughnutDataValues,
        backgroundColor: [
          "#FF6384", // Role 1
          "#36A2EB", // Role 2
          "#FFCE56", // Role 3
          "#4BC0C0", // Role 4
          "#9966FF", // Role 5
        ],
      },
    ],
  };

  /* 
  Original Code for Simple Doughnut Chart
  // Doughnut chart data
  const doughnutData = {
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  }; */

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Reports
              </h1>
              <p className="mt-2 text-xs text-gray-700">
                A list of All the Reports
              </p>
            </div>
          </div>
          <div className="mx-auto max-w-7xl mt-5">
            <Filter setQueryFilters={setQueryFilters} filter={refetch} />
          </div>

          <div className="mt-6 grid grid-cols-12 gap-4">
            {/* Bar Chart */}
            <div className="col-span-7">
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 pt-5 pb-2.5 sm:px-6">Bar Chart</div>
                <div className="px-4 pb-5 pt-2.5 sm:p-6">
                  <Bar data={StackedBarData} options={StackedBarOptions} />
                </div>
              </div>
            </div>

            {/* Doughnut Chart */}
            <div className="col-span-5">
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 pt-5 pb-2.5 sm:px-6">Doughnut Chart</div>
                <div className="px-4 pb-5 pt-2.5 sm:p-6">
                  <Doughnut data={doughnutData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default withAuth(Report, [0, 1]);
